import React from "react";

const Circle = (props) => {
  const name = props.name + "-pillow.jpg";
  const color = props.color;

  return (
    <div
      onClick={() => {
        props.changeImg(name);
        props.changeColor(color);
      }}
    >
      <div className="circle">
        <img src={"fotos/90X90-" + props.name + ".png"} />
      </div>
      <p
        style={{
          fontFamily: "Roboto Mono, sans-serif",
          textTransform: "uppercase",
          fontWeight: 700,
          fontSize: "14px",
        }}
      >
        {props.name}
      </p>
    </div>
  );
};

export default Circle;
