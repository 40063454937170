import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="homepageDiv">
      <h2>Hubduction</h2>
    </div>
  );
}

export default Home;
