import React, {
  useRef,
  useEffect,
  Suspense,
  useState,
  createContext,
  useContext,
} from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
  useContextBridge,
  Text,
} from "@react-three/drei";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import Loader from "./Loader";
import ViewCube from "./ViewCube/ViewCube";
import { THREE } from "three";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";
import Circle from "./Circle";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import bgImg from "./bg.png";
import pillow from "./pillow.svg";

function ModelExplode(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/uploads_files_2249051_kissen12.glb");

  const uploadedMat = useRef();

  useEffect(() => {
    if (props.img) {
      const textureLoader = new TextureLoader();
      textureLoader.load(props.img, (t) => {
        uploadedMat.current.map = t;
        uploadedMat.current.needsUpdate = true;
      });
    }
  }, [props.img]);

  // let newColor = "#fff";
  // useEffect(() => {
  //   if (props.color) {
  //     console.log(props.color);
  //   }
  // }, [props.color]);

  return (
    <Center>
      <group {...props} dispose={null}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube.geometry}
          material={materials.SofaCloth}
          position={[1.27032149, -0.1699236, 0]}
          rotation={[0, 0, 0.22209963]}
          // material-color={props.color}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube001.geometry}
          position={[1.27032149, -0.17140312, 0]}
          rotation={[0, 0, 0.22209963]}
        >
          <meshBasicMaterial ref={uploadedMat} />
        </mesh>
      </group>
    </Center>
  );
}

useGLTF.preload("/uploads_files_2249051_kissen12.glb");

function Pillow() {
  const [img, setImg] = useState("");

  const ref = useRef();

  const [pop, setPop] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [value, setValue] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [color, setColor] = useState("");
  const changeImg = (img) => {
    setImg(img);
  };

  const changeColor = (color) => {
    setColor(color);
  };

  const goUp = (id) => {
    id.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const goDown = (id) => {
    id.scrollTo({
      top: id.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  const stopScroll = (id) => {
    id.scrollTop = id.scrollTop;
  };

  return (
    <>
      {!pop && (
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
            background: "#fff",
          }}
        >
          <div className="controlsMain">
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ paddingBottom: "50px" }}>
                <img
                  src="https://www.critec.pt/storage/company/critec_2022-09-06-15-12-29.png"
                  width="136"
                />
              </div>
              <div
                style={{
                  height: "900px",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "80px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  scrollBehavior: "smooth",
                }}
              >
                <FontAwesomeIcon
                  onPointerDown={(e) => goUp(ref.current)}
                  onPointerUp={(e) => stopScroll(ref.current)}
                  icon={faChevronUp}
                />
                <div
                  className="scrollElements"
                  style={{ textAlign: "center" }}
                  ref={ref}
                >
                  <Circle
                    changeImg={changeImg}
                    name={"Vidal"}
                    // changeColor={changeColor}
                    // color="#4EAF50"
                  />
                  <Circle
                    changeImg={changeImg}
                    name={"Bruno"}
                    // changeColor={changeColor}
                    // color="#FDC018"
                  />
                  <Circle changeImg={changeImg} name={"Barrocas"} />
                  <Circle changeImg={changeImg} name={"Artur"} />
                  <Circle changeImg={changeImg} name={"Diogo"} />
                  <Circle changeImg={changeImg} name={"Fatima"} />
                  <Circle changeImg={changeImg} name={"Filipa"} />
                  <Circle changeImg={changeImg} name={"Fred"} />
                  <Circle changeImg={changeImg} name={"Helder"} />
                  <Circle changeImg={changeImg} name={"Ivo"} />
                  <Circle changeImg={changeImg} name={"Joana"} />
                  <Circle changeImg={changeImg} name={"Joca"} />
                  <Circle changeImg={changeImg} name={"Leo"} />
                  <Circle changeImg={changeImg} name={"Luis"} />
                  <Circle changeImg={changeImg} name={"Magas"} />
                  <Circle changeImg={changeImg} name={"Marina"} />
                  <Circle changeImg={changeImg} name={"Mario"} />
                  <Circle changeImg={changeImg} name={"Neto"} />
                  <Circle changeImg={changeImg} name={"Resende"} />
                  <Circle changeImg={changeImg} name={"Sara"} />
                  <Circle changeImg={changeImg} name={"Sofia"} />
                  <Circle changeImg={changeImg} name={"Resende"} />
                </div>
                <FontAwesomeIcon
                  onPointerDown={(e) => goDown(ref.current)}
                  onPointerUp={(e) => stopScroll(ref.current)}
                  icon={faChevronDown}
                />
              </div>
            </div>
          </div>
          <div className="modelDiv">
            <Canvas camera={{ position: [1, 20, -5], fov: 15 }}>
              <Suspense fallback={<Loader />}>
                <OrbitControls rotateSpeed={0.7} maxDistance={10} />
                <ModelExplode img={img} 
                // color={color} 
                />
                <ViewCube />
                <Environment files={"hdr2.hdr"} />
              </Suspense>
            </Canvas>
          </div>
          <div className="bottomDiv">
            <p style={{ float: "left" }}>OFERTA GRÁTIS ATÉ 25 DE ABRIL 2023</p>
            <p style={{ float: "right", marginRight: "70px" }}>
              © 2023 CRITEC CREATIVE AGENCY ♥️ HUBDUCTION{" "}
            </p>
          </div>
          <div
            style={{
              position: "absolute",
              left: "73.5%",
              top: "46%",
              width: "171px",
            }}
          >
            <hr style={{ borderTop: "2px solid #1a1a1a" }} />
          </div>
          <div className="rightBlockDiv">
            <p
              className="robotoMono"
              style={{ fontSize: "24px", fontWeight: 700 }}
            >
              HEY
            </p>
            <p
              className="gabriela"
              style={{
                fontSize: "72px",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              RELAXA
            </p>
            <p className="roboto" style={{ fontSize: "16px", fontWeight: 900 }}>
              <span style={{ fontWeight: 400 }}>ACONCHEGO E </span>CONFORTO
              PREMIUM
            </p>
            <div className="spacer25"></div>
            <button
              className="robotoMono"
              style={{
                textTransform: "uppercase",
                borderRadius: "30px",
                width: "232px",
                height: "60px",
                background: "transparent",
                cursor: "pointer",
                fontSize: "22px",
                border: "1px solid #1a1a1a",
              }}
              onClick={() => {
                setPop(true);
              }}
            >
              <b>Encomenda já</b>
            </button>
          </div>
        </div>
      )}
      {pop && (
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundImage: `url(${bgImg})`,
          }}
        >
          <div className="critecLogo">
            <img
              src="https://www.critec.pt/storage/company/critec_2022-09-06-15-12-29.png"
              width="136"
            />
          </div>
          {!thanks && (
            <div
              className="closeBtnDiv"
              onClick={() => {
                setPop(false);
              }}
            >
              <button
                className="roboto"
                style={{
                  borderRadius: "50%",
                  background: "transparent",
                  fontSize: "25px",
                  width: "60px",
                  height: "60px",
                  border: "1px solid #1a1a1a",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  style={{ color: "#1a1a1a" }}
                />
              </button>
              <p
                className="robotoMono"
                style={{ fontSize: "22px", fontWeight: 700 }}
              >
                FECHAR
              </p>
            </div>
          )}
          <div className="popUpDiv">
            <div>
              <div className="popUpContainer">
                <img src={pillow} width="90" />
                {!thanks && (
                  <>
                    <p
                      className="gabriela"
                      style={{
                        fontWeight: 700,
                        fontSize: "65px",
                        marginBottom: 0,
                      }}
                    >
                      BOM DESCANSO
                    </p>
                    <p
                      className="robotoMono"
                      style={{
                        fontWeight: 700,
                        fontSize: "22px",
                        marginTop: 0,
                      }}
                    >
                      OU UMA BOA GUERRA DE ALMOFADAS
                    </p>

                    <div className="spacer60"></div>

                    <input
                      type="text"
                      className="input robotoMono"
                      placeholder="Nome"
                      style={{ marginBottom: "20px" }}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                    <input
                      type="email"
                      className="input robotoMono"
                      placeholder="Email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <div className="spacer30"></div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <span
                        className="robotoMono"
                        style={{ fontSize: "22px", fontWeight: 700 }}
                      >
                        TAMANHO
                      </span>
                      <label
                        className="robotoMono"
                        style={{ fontWeight: 700, fontSize: "22px" }}
                      >
                        <input
                          type="radio"
                          name="size"
                          value="50X50cm"
                          onClick={() => {
                            setValue(50);
                          }}
                        />
                        50x50cm
                      </label>
                      <label
                        className="robotoMono"
                        style={{ fontWeight: 700, fontSize: "22px" }}
                      >
                        <input
                          type="radio"
                          name="size"
                          value="120X120cm"
                          onClick={() => {
                            setValue(120);
                          }}
                        />
                        120x120cm
                      </label>
                    </div>

                    <div className="spacer60"></div>
                    <button
                      className="robotoMono"
                      disabled={name && email && value ? false : true}
                      onClick={() => {
                        setThanks(true);
                      }}
                      style={{
                        textTransform: "uppercase",
                        borderRadius: "30px",
                        width: "232px",
                        height: "60px",
                        background:
                          name && email && value ? "#1A1A1A" : "transparent",
                        cursor:
                          name && email && value ? "pointer" : "not-allowed",
                        color: name && email && value ? "#fff" : "gray",
                        fontSize: "22px",
                        fontWeight: 700,
                      }}
                    >
                      QUERO MUITO
                    </button>
                  </>
                )}
                {thanks && (
                  <div style={{ textAlign: "center" }}>
                    <p className="gabriela" style={{ fontSize: "50px" }}>
                      OBRIGADO!
                      <br /> <div className="spacer30"></div>
                      <span className="robotoMono">
                        Serão descontados do teu salário{" "}
                        {value === 50 ? (
                          <span style={{ fontWeight: 700 }}>x€</span>
                        ) : (
                          <span style={{ fontWeight: 700 }}>y€</span>
                        )}
                      </span>
                    </p>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <button
                        className="robotoMono"
                        onClick={() => {
                          setThanks(false);
                          setPop(false);
                          setTimeout(() => {
                            alert(
                              "Relaxa! É daquelas coisas de dia 1 de Abril!"
                            );
                          }, 100);
                        }}
                        style={{
                          textTransform: "uppercase",
                          borderRadius: "30px",
                          width: "232px",
                          height: "60px",
                          background: "#1A1A1A",
                          cursor: "pointer",
                          color: "#fff",
                          fontSize: "40px",
                          fontWeight: 700,
                        }}
                      >
                        🤩
                      </button>
                      <button
                        className="robotoMono"
                        onClick={() => {
                          setThanks(false);
                        }}
                        style={{
                          textTransform: "uppercase",
                          borderRadius: "30px",
                          width: "232px",
                          height: "60px",
                          background: "#1A1A1A",
                          cursor: "pointer",
                          color: "#fff",
                          fontSize: "40px",
                          fontWeight: 700,
                        }}
                      >
                        🤩
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Pillow;
